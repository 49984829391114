import React from "react";

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  theme: "light",
};

function reducer(state, action) {
  switch (action.type) {
    case "toggle_theme": {
      return {
        ...state,
        theme: state.theme === "light" ? "dark" : "light",
      };
    }
    case "load_playground": {
      return {
        ...state,
        playground: action.payload,
        authorEmail: action.author,
      };
    }
    case "show_modal": {
      return {
        ...state,
        showModal: action.payload,
        modalType: action.modalType,
      };
    }
    case "get_event": {
      return {
        ...state,
        event: action.payload,
      };
    }
    case "get_society": {
      return {
        ...state,
        society: action.payload,
      };
    }
    case "get_partner": {
      return {
        ...state,
        partner: action.payload,
      };
    }
    case "get_sponsor": {
      return {
        ...state,
        sponsor: action.payload,
      };
    }
    case "get_team": {
      return {
        ...state,
        team: action.payload,
      };
    }
    case "get_article": {
      return {
        ...state,
        article: action.payload,
      };
    }
    case "get_user_request": {
      return {
        ...state,
        userRequest: action.payload,
      };
    }
    case "set_entity_data_to_update": {
      return {
        ...state,
        eventDataToUpdate: action.payload,
      };
    }
    case "playground_data_to_update": {
      return {
        ...state,
        playgroundDataToUpdate: action.payload,
      };
    }
    case "search_player_username": {
      return {
        ...state,
        searchResponse: action.payload,
      };
    }
    default:
      throw new Error("Pas bon le action type");
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
